import VueRouter from 'vue-router'

export default new VueRouter({
    routes: [
        {
            path: '/',
            name: 'homeScreen',
            component: () => import('../views/HomeScreen.vue')
        },
        {
            path: '/find-peer',
            name: 'FindPeer',
            component: () => import('../views/FindPeer.vue')
        },
        {
            path: '/messages',
            name: 'messageScreen',
            component: () => import('../views/MessageScreen.vue')
        },
        {
            path: '/settings',
            name: 'settingScreen',
            component: () => import('../views/SettingScreen.vue')
        },
        {
            path: '/profile',
            name: 'profileScreen',
            component: () => import('../views/ProfileScreen.vue')
        },
    ]
})
