import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router'

import mitt from 'mitt';
const emitter = mitt();

import router from './routes/router.js';
import './assets/styles/tailwind.css';

Vue.use(VueRouter);

const app = new Vue({
    router,
    render: h => h(App)
}).$mount('#app')

app.globalProperties.emitter = emitter;
