<template>
  <div>
      <div class="bg-gray-900 p-4 rounded console-window" v-if="displayConsole">
      <div class="console-content">
        <!-- Console output goes here -->
        <p v-for="log in logs" :key="log.message">
          <span :class="log.color">{{ log.time }} : {{ log.message }}</span>
        </p>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script lang="js">
import Vue from 'vue';
import "babel-polyfill";
import Libp2p from "libp2p";
import Websockets from "libp2p-websockets";
import WebRTCStar from "libp2p-webrtc-star";
import {
    NOISE
} from "libp2p-noise";
import Mplex from "libp2p-mplex";
import Bootstrap from "libp2p-bootstrap";
import KadDHT from "libp2p-kad-dht";
import PeerId from "peer-id";
import pushable from "it-pushable";
import pipe from "it-pipe";
// import GossipSub from 'libp2p-gossipsub';
const Gossipsub = require('libp2p-gossipsub')

import axios from 'axios';

import {
    array2str,
    str2array
} from "./utils";
import Swal from 'sweetalert2';

const chatProtocol = "/chat/1.0.0";
const nameTopic = "abchat-name"
import {
    EventBus
} from './EventBus';

let url = "https://abchat.alphasofthub.com?path=:path";

export default {
    name: 'FindPeer',
    data() {
        return {
            displayConsole: false,
            name: "",
            libp2p: null,
            myPeerId: "",
            otherPeerId: "",
            otherPeerMultiaddrs: [],
            otherPeerProtocols: [],
            otherPeerMultiaddr: "",
            otherPeerProtocol: "",
            remotePeerId: "",
            chatMessage: "",
            messages: [],
            chatQueue: null,
            connectedPeers: new Set(),
            connectedPeersArr: [],
            connectedPeersNames: [],
            version: 0,
            gsub: null,
            peers: [],
            logs: [],
        }
    },
    async created() {
        // check if name exists in localstorage.
        if (localStorage.getItem("name")) {
            this.name = localStorage.getItem("name");
            await this.init();
            return;
        }
        Swal.fire({
            title: 'Enter Your Username',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Save',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: (username) => {
                this.name = username;
                let u = url.replace(":path", "addPeer");
                // post req as josn time send username:name
                return axios.post(u, {
                    username: username
                }).then((result) => {
                    // check if result.data contain error.
                    if (result.data.error) {
                        Swal.showValidationMessage(
                            `Request failed: ${result.data.error}`
                        )
                        return false;
                    }
                    // save name in localstorage.
                    localStorage.setItem("name", this.name);
                    return true;
                }).catch((err) => {
                    Swal.showValidationMessage(
                        `Request failed: ${err}`
                    )
                })
            },
        }).then(async (result) => {
            // if return true then get peer id.
            if (result.value) {
                await this.init();
            } else {
                // if return false then close the app.
                Swal.fire({
                    title: 'Error',
                    text: 'Something went wrong!',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                }).then(() => {
                    window.close();
                })
            }
        });
    },
    mounted() {
        EventBus.$on('selectPeer', (peer) => {
            this.otherPeerId = peer;
            this.findOtherPeer();
            console.log("Select Peer: ", peer);
        });
        EventBus.$on('sendMsgToPeer', (msg) => {
            this.chatMessage = msg;
            console.log("Sending msg to ", this.remotePeerId.toString(), "msg", this.chatMessage)
            this.sendMessage();
        });
        EventBus.$on('openConsole', (data) => {
            this.displayConsole = !this.displayConsole;
        });
    },
    watch: {
        remotePeerId() {
            this.otherPeerId = this.remotePeerId;
            EventBus.$emit('updateRemotePeerId', this.remotePeerId);
            this.findOtherPeer();
        },
        otherPeerProtocols() {
            for (let i = 0; i < this.otherPeerProtocols.length; i++) {
                this.logs.push({
                    "color": "text-blue-400",
                    "message": "Protocol: " + this.otherPeerProtocols[i],
                    "time": new Date().toLocaleString()
                })
            }
        },
    },
    methods: {
        getConnectedPeers() {
            let peers = [];
            let u = url.replace(":path", "updatePeerStatus");
            axios.post(u, {
                "username": this.name
            }).then((result) => {
                this.peers = result.data;
                let self_peer = localStorage.getItem("name");
                this.peers = this.peers.filter((peer) => {
                    return peer.username != self_peer;
                });
                EventBus.$emit('updateConnectedPeers', this.peers);
                this.logs.push({
                    "color": "text-blue-400",
                    "message": "Connected Peers updated",
                    "time": new Date().toLocaleString()
                })
            }).catch((err) => {
                Swal.fire({
                    title: 'Error',
                    text: err,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
        },
        updatePeerId() {
            let u = url.replace(":path", "updatePeerID");
            axios.post(u, {
                "username": this.name,
                "peer_id": this.myPeerId
            }).then((result) => {
                this.logs.push({
                    "color": "text-blue-400",
                    "message": "Peer ID updated to " + this.myPeerId,
                    "time": new Date().toLocaleString()
                })
            }).catch((err) => {})
        },
        async init() {
            this.libp2p = await Libp2p.create({
                addresses: {
                    listen: [
                        "/dns4/wrtc-star1.par.dwebops.pub/tcp/443/wss/p2p-webrtc-star",
                        "/dns4/wrtc-star2.sjc.dwebops.pub/tcp/443/wss/p2p-webrtc-star",
                    ],
                },
                modules: {
                    transport: [Websockets, WebRTCStar],
                    connEncryption: [NOISE],
                    streamMuxer: [Mplex],
                    peerDiscovery: [Bootstrap],
                    dht: KadDHT,
                    // pubsub: GossipSub,
                },
                config: {
                    peerDiscovery: {
                        [Bootstrap.tag]: {
                            enabled: true,
                            list: [
                                "/dnsaddr/bootstrap.libp2p.io/p2p/QmNnooDu7bfjPFoTZYxMNLWUQJyrVwtbZg5gBMjTezGAJN",
                                "/dnsaddr/bootstrap.libp2p.io/p2p/QmbLHAnMoJPWSCR5Zhtx6BHJX9KiKNN6tpvbUcqanj75Nb",
                                "/dnsaddr/bootstrap.libp2p.io/p2p/QmZa1sAxajnQjVM8WjWXoMbmPd7NsWhfKsPkErzpm9wGkp",
                                "/dnsaddr/bootstrap.libp2p.io/p2p/QmQCU2EcMqAqQPR2i9bChDtGNJchTbq5TbXJJ16u19uLTa",
                                "/dnsaddr/bootstrap.libp2p.io/p2p/QmcZf59bWwK5XFi76CZX8cbJ4BhTzzA3gU1ZjYZcYW3dwt",
                            ],
                        },
                    },
                    dht: {
                        enabled: true,
                    },
                },
            });
            await this.libp2p.start();
            this.myPeerId = this.libp2p.peerId.toB58String();

            this.logs.push({
                'color': "text-green-400",
                "message": "Your Peer ID: " + this.myPeerId,
                "time": new Date().toLocaleTimeString(),
            });

            // when peerID recive update the peerID.
            if (this.myPeerId) {
                this.updatePeerId();
            }

            // get connected peers, after every 1 sec.
            setInterval(() => {
                this.getConnectedPeers();
            }, 1000);

            this.gsub = new Gossipsub(this.libp2p, {
                // Stub function, use your own peer scoring function here.
            })

            await this.gsub.start()

            // https://www.npmjs.com/package/libp2p-gossipsub

            this.gsub.on(nameTopic, (message) => {
                console.log('Received message on nameTopic:', message);
                const dataArray = Object.values(message.data);
                const name = new TextDecoder().decode(new Uint8Array(dataArray));
                const PeerID = message.from
                const obj = {
                    peerId: PeerID,
                    name: name,
                };
                if (name.endsWith(":abchat")) {
                    this.connectedPeers.add(PeerID);
                    this.connectedPeersNames.push(obj);
                }
                EventBus.$emit('updateConnectedPeers', this.connectedPeersNames);
                console.log("NAME MSG:", obj, this.connectedPeersNames);
            })

            this.gsub.subscribe(nameTopic);

            // discover connected peers
            this.libp2p.on("peer:discovery", (peerId) => {
                this.sendName();
                this.logs.push({
                    "color": "text-yellow-400",
                    "message": "Found peer: " + peerId.toB58String(),
                    "time": new Date().toLocaleTimeString(),
                });
                console.log("Connected peerID: ", peerId);
                this.version++;
            });
            this.libp2p.on("peer:connect", () => {
                this.sendName();
                console.log("Connected peers names: ", this.connectedPeersNames);
                this.version++;
            });
            // Remove disconnected peers.
            this.libp2p.on("peer:disconnect", (peerId) => {
                this.connectedPeers.delete(peerId.toB58String());
                this.logs.push({
                    "color": "text-red-400",
                    "message": "Disconnected peer: " + peerId.toB58String(),
                    "time": new Date().toLocaleTimeString(),
                });
                this.connectedPeersNames = this.connectedPeersNames.filter((peer) => peer.peerId !== peerId.toB58String());
                console.log("Connected peers names: ", this.connectedPeersNames);
                this.version++;
            });

            // this.libp2p.pubsub.subscribe(nameTopic, (message) => {

            //     console.log("Received message from: ", message);
            //     const name = message.data.toString();
            //         if (name.endsWith(":abchat")) {
            //             this.connectedPeers.add(message.from);
            //             this.connectedPeersNames.push({
            //                 peerId: message.from,
            //                 name: name.slice(0, -7)
            //             });
            //             EventBus.$emit('updateConnectedPeers', this.connectedPeersNames);
            //             console.log("Connected peers: ", this.connectedPeers);
            //             console.log("Connected peers names: ", this.connectedPeersNames);
            //             this.connectedPeersArr = this.setToArray(this.connectedPeers);
            //             this.version++;
            //             console.log("version: ", this.version);
            //         }
            //  });
            this.sendName();

            this.libp2p.handle(chatProtocol, ({
                connection,
                stream,
                protocol
            }) => {
                this.remotePeerId = connection.remoteAddr.getPeerId();
                pipe(
                    stream,
                    (source) => {
                        return (async function* () {
                            for await (const buf of source) yield array2str(buf.slice());
                        })();
                    },
                    async (source) => {
                        for await (const msg of source) {
                            this.messages.push(msg);
                            // recieve msg.
                            EventBus.$emit('updateMessages', msg);
                            this.logs.push({
                                "color": "text-green-400",
                                "message": "Received message: " + msg,
                                "time": new Date().toLocaleTimeString(),
                            });
                        }
                    }
                );
            });
        },
        sendName() {
            try {
                console.log('Sending name:', this.name);
                this.gsub.publish(nameTopic, new TextEncoder().encode(this.name));
                console.log('Name sent successfully');
            } catch (err) {
                console.error('Failed to send name:', err);
            }
        },
        async findOtherPeer() {
            let peerId = PeerId.parse(this.otherPeerId);
            let result = await this.libp2p.peerRouting.findPeer(peerId);
            this.otherPeerMultiaddrs = result.multiaddrs;
            this.otherPeerProtocols = this.libp2p.peerStore.protoBook.get(peerId);
            this.otherPeerMultiaddr = this.otherPeerMultiaddrs[0];
            this.otherPeerProtocol = chatProtocol;
            this.dialProtocol();
        },

        async dialProtocol() {
            let peerId = PeerId.parse(this.otherPeerId);
            const {
                stream,
                protocol
            } = await this.libp2p.dialProtocol(
                peerId,
                chatProtocol
            );
            this.chatQueue = pushable();
            pipe(
                this.chatQueue,
                (source) => {
                    return (async function* () {
                        for await (const msg of source) yield str2array(msg);
                    })();
                },
                stream
            );
            this.logs.push({
                "color": "text-green-400",
                "message": "Connected to peer: " + this.otherPeerId,
                "time": new Date().toLocaleTimeString(),
            });
        },

        sendMessage() {
            this.chatQueue.push(this.chatMessage);
            this.messages.push(this.chatMessage);
            this.chatMessage = "";
            this.logs.push({
                "color": "text-greej-400",
                "message": "Sent message: " + this.chatMessage,
                "time": new Date().toLocaleTimeString(),
            });
        }
    }
};
</script>

<style scoped>
.console-window {
  height: 50vh;
  overflow-y: auto;
}
</style>
